import React from "react"
import Typography from '@material-ui/core/Typography';
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Typography variant="h1" align="center" gutterBottom>NOT FOUND :-(</Typography>
    <Typography variant="body1" align="center">We couldn't find that page. Sadness...</Typography>
  </Layout>
)

export default NotFoundPage
